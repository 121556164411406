"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const ROUTES_1 = require("uni/components/ROUTES");
const useCurrentMonth_1 = require("./useCurrentMonth");
const useGetCopy = ({ activeWorkspaceId, selfView = true, isTeamMember = false, hasTeammates, includedHours = 0, hourlyPrice = 65, reachedLimit, hoursLimit, isAllowedPersonal, kind, isOnMaxio, }) => {
    const note = (0, react_1.useMemo)(() => {
        const teamPlanText = hasTeammates ? `team` : '';
        if (!includedHours) {
            const accountText = hasTeammates ? `Your team has` : `You have`;
            const accountInfoText = isTeamMember
                ? `selected by your team admin`
                : `you select`;
            return {
                text: `${accountText} not selected a plan yet.`,
                infoText: !isOnMaxio
                    ? `Your first month includes 10h at $499, then a variable plan minimum and hourly rate ${accountInfoText}.`
                    : undefined,
                cta: isOnMaxio
                    ? undefined
                    : (selfView && !hasTeammates) || (!selfView && !isTeamMember)
                        ? {
                            text: 'Manage my plan',
                            link: `https://app.withdouble.com${ROUTES_1.MATCHED_WORKSPACES_ROUTE}/${activeWorkspaceId}/settings/billing`,
                        }
                        : undefined,
            };
        }
        if (kind === useCurrentMonth_1.MonthKind.NO_USAGE) {
            if (isTeamMember && hoursLimit && selfView) {
                const andPersonal = isAllowedPersonal ? `and personal` : '';
                return {
                    text: `You have ${hoursLimit}h to delegate work ${andPersonal} tasks.`,
                    infoText: `This is a suggested monthly limit for individuals, set by your team admin.`,
                };
            }
            else {
                return {
                    text: `Your ${teamPlanText} plan includes ${includedHours}h.`,
                    infoText: `Once your team reaches ${includedHours}h, each additional hour will be billed at $${hourlyPrice}/hr.`,
                };
            }
        }
        if (kind === useCurrentMonth_1.MonthKind.FIRST_MONTH) {
            if (isOnMaxio) {
                return {
                    text: `Your ${teamPlanText} plan includes ${includedHours}h.`,
                };
            }
            const text = `Your first month will be billed at the cheapest option between the plan you have selected and the plan's hourly rate.`;
            if (!isTeamMember) {
                return {
                    text,
                };
            }
            return { text };
        }
        // TeamMember Selfview is always the same regardless if over/under limit
        if (isTeamMember && selfView && hoursLimit) {
            const text = `of ${hoursLimit}h`;
            const infoText = `This is a suggested monthly limit for individuals, set by your team admin.`;
            return { text, infoText };
        }
        if (reachedLimit) {
            if (isTeamMember && selfView && hoursLimit) {
                const text = `of ${hoursLimit}h`;
                const infoText = `This is a suggested monthly limit for individuals, set by your team admin.`;
                return { text, infoText };
            }
            const teamOrIndividualText = hasTeammates
                ? `Your team has used `
                : `You've used`;
            const text = `${teamOrIndividualText} all ${includedHours}h in the plan.`;
            const infoText = `Each additional hour will be billed at $${hourlyPrice}/hr`;
            if (isTeamMember)
                return { text, infoText };
            return {
                text,
                infoText,
                cta: {
                    text: 'Manage my plan',
                    link: `https://app.withdouble.com${ROUTES_1.MATCHED_WORKSPACES_ROUTE}/${activeWorkspaceId}/settings/billing`,
                },
            };
        }
        else {
            const text = `of ${includedHours}h`;
            const teamOrIndividualText = hasTeammates
                ? `your Team reaches`
                : 'you reach';
            const infoText = `Once ${teamOrIndividualText} ${includedHours}h, each additional hour will be billed at $${hourlyPrice}/hr.`;
            return { text, infoText: isOnMaxio ? undefined : infoText };
        }
    }, [
        hasTeammates,
        includedHours,
        kind,
        isTeamMember,
        selfView,
        hoursLimit,
        reachedLimit,
        isOnMaxio,
        activeWorkspaceId,
        isAllowedPersonal,
        hourlyPrice,
    ]);
    return note;
};
exports.default = useGetCopy;
