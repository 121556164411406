"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCurrentMonth = exports.MonthKind = void 0;
const luxon_1 = require("luxon");
const react_1 = require("react");
const generated_1 = require("uni/graphql/hooks/generated");
var MonthKind;
(function (MonthKind) {
    MonthKind[MonthKind["FIRST_MONTH"] = 0] = "FIRST_MONTH";
    MonthKind[MonthKind["NO_USAGE"] = 1] = "NO_USAGE";
    MonthKind[MonthKind["SOME_USAGE"] = 2] = "SOME_USAGE";
    MonthKind[MonthKind["REPORT_BUILDING"] = 3] = "REPORT_BUILDING";
})(MonthKind = exports.MonthKind || (exports.MonthKind = {}));
const useCurrentMonth = () => {
    var _a, _b;
    const { data, loading, error } = (0, generated_1.useGetWorkspaceUsageQuery)({});
    const { me, workspace } = data || {};
    const { id: workspaceId, usage: workspaceUsage, team } = workspace || {};
    const teamWorkspaces = (0, react_1.useMemo)(() => (team === null || team === void 0 ? void 0 : team.workspaces) || [], [team === null || team === void 0 ? void 0 : team.workspaces]);
    const hasTeammates = teamWorkspaces ? teamWorkspaces.length > 1 : false;
    const isTeamMember = me === null || me === void 0 ? void 0 : me.isTeamMember;
    const isFirstMonth = !isTeamMember &&
        workspaceUsage &&
        luxon_1.DateTime.now() < luxon_1.DateTime.fromISO(workspaceUsage.startDate).endOf('month');
    const teamWorkspaceUsages = (0, react_1.useMemo)(() => {
        return teamWorkspaces
            .filter((x) => x)
            .sort((a, b) => {
            // Descending order of usage
            const bMonth = b.usage && b.usage.monthlyUsages[0];
            const aMonth = a.usage && a.usage.monthlyUsages[0];
            return ((bMonth && bMonth.hours) || 0) - ((aMonth && aMonth.hours) || 0);
        });
    }, [teamWorkspaces]);
    const publicTeamUsageHours = (team === null || team === void 0 ? void 0 : team.usageHours) || 0;
    const selfUsageFromTeam = (0, react_1.useMemo)(() => {
        const self = teamWorkspaces.find((member) => {
            return member.id === workspaceId;
        });
        return self && self.usage;
    }, [workspaceId, teamWorkspaces]);
    // Use workspaceUsage first because it refers to the workspace not the executiveId
    const selfUsage = workspaceUsage || selfUsageFromTeam;
    const { subscription } = team || {};
    const includedHours = ((_a = subscription === null || subscription === void 0 ? void 0 : subscription.currentBillingCycle) === null || _a === void 0 ? void 0 : _a.allocatedHours) || 0;
    const isOnMaxio = (subscription === null || subscription === void 0 ? void 0 : subscription.type) === generated_1.TeamSubscriptionType.MAXIO;
    const { hoursLimit = 0, isAllowedPersonal = false } = selfUsage || {};
    const hourlyPrice = (_b = subscription === null || subscription === void 0 ? void 0 : subscription.currentBillingCycle) === null || _b === void 0 ? void 0 : _b.product.hourlyPrice;
    const hasSomeTeamUsage = (0, react_1.useMemo)(() => {
        return teamWorkspaceUsages.some((member) => {
            return (member.usage &&
                member.usage.monthlyUsages.some((usage) => {
                    return usage.hours > 0;
                }));
        });
    }, [teamWorkspaceUsages]);
    const hasSomeSelfUsage = (0, react_1.useMemo)(() => {
        return (selfUsage &&
            selfUsage.monthlyUsages.some((usage) => {
                return usage.hours > 0;
            }));
    }, [selfUsage]);
    const currentMonth = (0, react_1.useMemo)(() => {
        if (selfUsage) {
            const common = {
                selfUsage,
                teamWorkspaceUsages,
                includedHours,
                hoursLimit,
                isAllowedPersonal,
                hourlyPrice,
                hasTeammates,
                isTeamMember,
                publicTeamUsageHours,
                isOnMaxio,
            };
            if (isFirstMonth) {
                return Object.assign({ kind: MonthKind.FIRST_MONTH }, common);
            }
            else if (!hasSomeSelfUsage && !hasSomeTeamUsage) {
                return Object.assign({ kind: MonthKind.NO_USAGE }, common);
            }
            else {
                return Object.assign({ kind: MonthKind.SOME_USAGE }, common);
            }
        }
        else {
            return undefined;
        }
    }, [
        selfUsage,
        teamWorkspaceUsages,
        includedHours,
        hoursLimit,
        isAllowedPersonal,
        hourlyPrice,
        hasTeammates,
        isTeamMember,
        publicTeamUsageHours,
        isOnMaxio,
        isFirstMonth,
        hasSomeSelfUsage,
        hasSomeTeamUsage,
    ]);
    return {
        currentMonth,
        loading,
        error,
    };
};
exports.useCurrentMonth = useCurrentMonth;
