"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const getCurrencySymbol = (currency) => currency === 'USD'
    ? '$'
    : currency === 'EUR'
        ? '€'
        : currency === 'GBP'
            ? '£'
            : '';
exports.default = getCurrencySymbol;
