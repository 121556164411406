"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const luxon_1 = require("luxon");
const react_1 = __importStar(require("react"));
const InfoMessage_1 = __importStar(require("uni/system/InfoMessage"));
const styled_1 = require("../styled");
const formatDay = (day) => {
    if (day === 1) {
        return '1st';
    }
    if (day === 2) {
        return '2nd';
    }
    if (day === 3) {
        return '3rd';
    }
    return `${day}th`;
};
const PendingPlanBanner = ({ nextBillingCycle: { startDate, product }, }) => {
    const startOfNextCycle = (0, react_1.useMemo)(() => {
        return luxon_1.DateTime.fromISO(startDate);
    }, [startDate]);
    return (react_1.default.createElement(InfoMessage_1.default, { level: InfoMessage_1.LEVEL.WARN, isComposerMessage: true },
        react_1.default.createElement(styled_1.MessageContainer, null,
            'Pending plan change',
            react_1.default.createElement("span", null,
                `Starting ${startOfNextCycle.toLocaleString(luxon_1.DateTime.DATE_FULL)}, you will be on the `,
                react_1.default.createElement("strong", null, ` ${product.name} `),
                'plan, which includes',
                react_1.default.createElement("strong", null, ` ${product.includedHours}h/mo`),
                `, renewing monthly on the ${formatDay(startOfNextCycle.day)}.`))));
};
exports.default = PendingPlanBanner;
