"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DISABLED_WORKSPACE_STATUSES = void 0;
const generated_1 = require("uni/graphql/hooks/generated");
exports.DISABLED_WORKSPACE_STATUSES = [generated_1.TeamStatus.PAUSED, generated_1.TeamStatus.AWAY];
const isWorkspacePaused = (workspace) => {
    var _a;
    return (((_a = workspace === null || workspace === void 0 ? void 0 : workspace.team) === null || _a === void 0 ? void 0 : _a.status) &&
        exports.DISABLED_WORKSPACE_STATUSES.includes(workspace.team.status));
};
exports.default = isWorkspacePaused;
