"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.absolute = void 0;
const styled_components_1 = require("styled-components");
exports.absolute = (0, styled_components_1.css) `
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
